import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Grid, MenuItem, Modal, Stack, Switch, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addGroupBooking, getSubAgentsForDropdown } from '../../store/actions';
import { CssTextField } from '../../components/TextField';
import { Add, ArrowBack, Done } from '@mui/icons-material';
import { tokens } from '../../theme';
import { toast } from 'react-toastify';
import AddNewSubAgentModalContent from '../agents/addNewSubAgentModalContent';
import AddNewAgentModalContent from '../agents/addNewAgentModalContent';
import titles from '../../data/nameTitles';
import SearchAndSelectInput from '../../components/SearchAndSelect';
import PlusOrMinusButton from '../../components/PlusOrMinusButton';
import moment from 'moment';

const initialValues = {
    name: "",
    email: "",
    title: "Mr",
    // cutOffDays: 7,
    cutOffDate: moment().format("YYYY-MM-DD"),
}

function GroupBookingPage2({ roomsData, totalAmount, agentsList, subAgentsList, isLoading, setPage, navigate }) {
    const theme = useTheme();
    const cutOffRef = useRef();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [status, setStatus] = useState("confirmed");
    const [values, setValues] = useState(initialValues);
    const [agent, setAgent] = useState("");
    const [subAgent, setSubAgent] = useState("");
    const [agentEnabled, setAgentEnabled] = useState(false);
    const [addAgentModal, setAddAgentModal] = useState(false);
    const [addSubAgentModal, setAddSubAgentModal] = useState(false);
    const [extraBedsEnabled, setExtraBedsEnabled] = useState(false);
    const [extraBeds, setExtraBeds] = useState(1);
    const [cutOffEnabled, setCutOffEnabled] = useState(false);
    // eslint-disable-next-line
    const [temp, setTemp] = useState("");

    const handleChange = (event) => {
        let fieldname = event?.target?.name;
        let data = values;
        data[fieldname] = event?.target?.value;
        setTemp(event.target.value);
        setValues(data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(agentEnabled && agent === ""){
            return toast.error("Please select an agent");
        }
        // else if(agentEnabled && agent !== ""){
        //     if(subAgent === ""){
        //         return toast.error("Please select a sub agent");
        //     } else {
        //         let filtered = subAgentsList?.filter((e) => e._id === subAgent)
        //         if(!filtered?.length){
        //             return toast.error("Please select a valid sub agent");
        //         }
        //     }
        // }

        if (cutOffEnabled && moment().format("YYYY-MM-DD") !== moment.unix(roomsData.arr[0].checkin_date).format("YYYY-MM-DD")) {
            if (moment().format("YYYY-MM-DD") === moment(values.cutOffDate).format("YYYY-MM-DD")) {
                return toast.error("Cut off date and checkin date must not be the same")
            }
        }

        let data = {
            ...values,
            status,
            agent_id: agent === "no_preference" ? "" : agent,
            sub_agent_id: subAgent === "no_preference" ? "" : subAgent,
            rooms: roomsData.arr.map((e) => ({
                checkin_date: e.checkin_date,
                checkout_date: e.checkout_date,
                room_id: e.room_id,
                room_number: e.room_number,
                no_of_adults: e.no_of_adults,
                no_of_childs: e.no_of_childs,
                meal_plan_id: e.meal_plan_id || "",
            })),
            cutOffDate: cutOffEnabled && values.cutOffDate ? moment(values.cutOffDate, "YYYY-MM-DD").startOf("day").unix() : 0,
            
        }
        if (data.paid_amount === ""){
            delete data.paid_amount;
        }
        if (data.custom_price === ""){
            delete data.custom_price;
        }
        // if (!cutOffEnabled || data.cutOffDays === ""){
        //     delete data.cutOffDays;
        // }
        if (extraBedsEnabled) {
            data.extra_beds = extraBeds;
        }
        console.log(data);

        let res = await dispatch(addGroupBooking(data));
        if(res){
            setValues(initialValues);
            localStorage.removeItem("grpBooking");
            navigate("/calendar");
            window.location.reload();
        }
    }

    const handleAgentChange = (agent_id) => {
        setAgent(agent_id)
        setSubAgent("")
    }
    const fetchSubAgents = async () => {
        if(agent !== ""){
            dispatch(getSubAgentsForDropdown({ id: agent }));
        }
    }
    useEffect(() => {
        fetchSubAgents()
    // eslint-disable-next-line
    },[agent])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container columnSpacing={{ md: 4, xs: 2 }} rowSpacing={2}>
                    <Grid item md={6} xs={12}>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    Title:
                                </Typography>
                                <CssTextField
                                    select
                                    fullWidth
                                    required
                                    size='small'
                                    name="title"
                                    onChange={handleChange}
                                    value={values.title}
                                >
                                    {titles?.map((title, index) => (
                                        <MenuItem value={title} key={index}>
                                            {title}
                                        </MenuItem>
                                    ))}
                                </CssTextField>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    Name: *
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    required
                                    size='small'
                                    type="text"
                                    placeholder="Name *"
                                    name="name"
                                    value={values?.name || ""}
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    {`Email:`}
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    // required={!agentEnabled}
                                    size='small'
                                    type="email"
                                    placeholder={`Email`}
                                    name="email"
                                    value={values?.email || ""}
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    {`Mobile:`}
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    // required={!agentEnabled}
                                    size='small'
                                    type="number"
                                    placeholder={`Mobile`}
                                    name="guest_mobile"
                                    value={values?.guest_mobile || ""}
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    Paid amount:
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    size='small'
                                    type="number"
                                    placeholder="Paid amount"
                                    name="paid_amount"
                                    value={values?.paid_amount || ""}
                                    onChange={handleChange}
                                    InputProps={{ inputProps: { min: 0, max: values?.custom_price !== "" ? values?.custom_price : totalAmount } }}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    Special price:
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    size='small'
                                    type="number"
                                    placeholder="Special price"
                                    name="custom_price"
                                    value={values?.custom_price ?? ""}
                                    onChange={handleChange}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    helperText={`Rack rate: ${totalAmount}`}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    Status:
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    select
                                    size="small"
                                    value={status}
                                    label="Status"
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <MenuItem value={"confirmed"}>
                                        {"Confirmed"}
                                    </MenuItem>
                                    <MenuItem value={"hold"}>
                                        {"Block"}
                                    </MenuItem>
                                </CssTextField>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    Preference:
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    placeholder="Preference"
                                    name="preference"
                                    value={values.preference || ""}
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    Billing Instruction:
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    placeholder="Billing Instruction"
                                    name="billingInstruction"
                                    value={values.billingInstruction || ""}
                                    onChange={handleChange}
                                />
                            </Stack>

                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    Address:
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    size='small'
                                    type="text"
                                    placeholder="Address"
                                    name="guest_address"
                                    value={values?.guest_address || ""}
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Typography width={"70%"}>
                                    Remarks:
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    size='small'
                                    type="text"
                                    placeholder="Remarks"
                                    name="remarks"
                                    value={values?.remarks || ""}
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <Typography noWrap>Agent booking</Typography>
                                <Switch
                                    checked={agentEnabled}
                                    onChange={(e) => setAgentEnabled(e.target.checked)}
                                />
                            </Stack>
                            {agentEnabled && (
                                <>
                                    <Stack direction="row" spacing={2}>
                                        <Typography width={"70%"}>
                                            Agent / Agency:
                                        </Typography>
                                        <SearchAndSelectInput
                                            keyname="first_name"
                                            value={agent}
                                            required={agentEnabled}
                                            list={agentsList}
                                            onChange={(id) => handleAgentChange(id)}
                                        />
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            title="Add new agent"
                                            onClick={() => setAddAgentModal(true)}
                                        >
                                            <Add />
                                        </Button>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <Typography width={"70%"}>
                                            Sub Agent:
                                        </Typography>
                                        <SearchAndSelectInput
                                            keyname="name"
                                            value={subAgent}
                                            // required={agentEnabled}
                                            list={subAgentsList}
                                            disabled={!agent || agent === ""}
                                            onChange={(id) => setSubAgent(id)}
                                        />
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            title="Add new sub agent"
                                            onClick={() => setAddSubAgentModal(true)}
                                            disabled={!agent || agent === ""}
                                        >
                                            <Add />
                                        </Button>
                                    </Stack>
                                </>
                            )}
                            <Stack direction="row" alignItems="center">
                                <Typography noWrap>Extra beds</Typography>
                                <Switch
                                    checked={extraBedsEnabled}
                                    onChange={(e) => setExtraBedsEnabled(e.target.checked)}
                                />
                            </Stack>
                            {extraBedsEnabled && (
                                <Stack direction="row" spacing={2}>
                                    <Typography width={"70%"}>
                                        Number of extra beds:
                                    </Typography>
                                    <PlusOrMinusButton
                                        min={1}
                                        max={10}
                                        size="small"
                                        name="extraBeds"
                                        value={extraBeds}
                                        handleChange={(value) => setExtraBeds(value)}
                                    />
                                </Stack>
                            )}
                            <Stack direction="row" alignItems="center">
                                <Typography noWrap>Cut off</Typography>
                                <Switch
                                    checked={cutOffEnabled}
                                    onChange={(e) => setCutOffEnabled(e.target.checked)}
                                />
                            </Stack>
                            {cutOffEnabled && (
                                <Stack direction="row" spacing={2}>
                                    <Typography width={"70%"}>
                                        Cut off date:
                                    </Typography>
                                    <CssTextField
                                        fullWidth
                                        required
                                        size='small'
                                        type="date"
                                        // placeholder="Cut off date"
                                        // label="Cut off date"
                                        onChange={handleChange}
                                        name="cutOffDate"
                                        value={values.cutOffDate}
                                        inputProps={{ min: moment().format("YYYY-MM-DD"), max: moment.unix(roomsData.arr[0].checkin_date).format("YYYY-MM-DD") }}
                                        // error={!!touched.cutOffDate && !!errors.cutOffDate}
                                        // helperText={touched.cutOffDate && errors.cutOffDate}
                                        inputRef={cutOffRef}
                                        onClick={() => cutOffRef.current.showPicker()}
                                    />
                                    {/* <CssTextField
                                        fullWidth
                                        required
                                        size="small"
                                        type="number"
                                        placeholder="Cut off period (in days)"
                                        name="cutOffDays"
                                        value={values.cutOffDays || ""}
                                        onChange={handleChange}
                                        InputProps={{ inputProps: { min: 0 } }}
                                    /> */}
                                </Stack>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                variant="outlined"
                                startIcon={<ArrowBack />}
                                onClick={() => setPage(1)}
                            >
                                <b>Go back</b>
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                endIcon={<Done />}
                            >
                                {isLoading ? <CircularProgress color="inherit" size={24} /> : <b>Book Now</b>}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>

            <Modal
                open={addAgentModal}
                onClose={() => setAddAgentModal(false)}
            >
                <>
                    <AddNewAgentModalContent
                        dispatch={dispatch}
                        isLoading={isLoading}
                        setModal={setAddAgentModal}
                        setAgent={setAgent}
                        colors={colors}
                    />
                </>
            </Modal>

            <Modal
                open={addSubAgentModal}
                onClose={() => setAddSubAgentModal(false)}
            >
                <>
                    <AddNewSubAgentModalContent
                        id={agent}
                        dispatch={dispatch}
                        isLoading={isLoading}
                        setModal={setAddSubAgentModal}
                        setSubAgent={setSubAgent}
                        colors={colors}
                    />
                </>
            </Modal>
        </>
    )
}

export default GroupBookingPage2;