/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Box, Checkbox, Chip, Grid, MenuItem, Stack, Switch, Typography } from '@mui/material';
import { CssTextField } from "../../components/TextField";
import { getMealPlansForDropdown, getSubAgentsForDropdown, updateBookingDatas } from "../../store/actions";
import { Formik } from "formik";
import { toast } from "react-toastify";
import titles from "../../data/nameTitles";
import SearchAndSelectInput from "../../components/SearchAndSelect";
import PlusOrMinusButton from "../../components/PlusOrMinusButton";
import moment from "moment";

const formatRooms = (rooms = []) => {
    return rooms?.map((e) => {
        return{
            _id: e?._id,
            room_no: e?.room_id?.room_no,
            dates: e?.dates,
            roomtype_id: e?.room_id?.roomtype_id?._id,
            no_of_adults: e?.no_of_adults,
            no_of_childs: e?.no_of_childs,
            meal_plan_id: e?.meal_plan?.plan,
        }
    })
}

export default function BookingUpdation({ bookingDetails, setUpdation, colors, dispatch, agentsList }) {
    const [agentEnabled, setAgentEnabled] = useState(bookingDetails?.agent_id?._id ? true : false);
    const [agent, setAgent] = useState(bookingDetails?.agent_id?._id ? bookingDetails?.agent_id?._id : "");
    const [subAgent, setSubAgent] = useState(bookingDetails?.sub_agent_id?._id ? bookingDetails?.sub_agent_id?._id : "");
    const [extraBedsEnabled, setExtraBedsEnabled] = useState(bookingDetails?.extra_beds ? true : false);
    const [customPriceEnabled, setCustomPriceEnabled] = useState(bookingDetails?.custom_price ? true : false);
    const [extraBeds, setExtraBeds] = useState(bookingDetails?.extra_beds ? bookingDetails?.extra_beds  : 1);
    const [subAgentsList, setSubAgentsList] = useState([]);
    const [notifyUser, setNotifyUser] = useState(false);
    const [mPlans, setMPlans] = useState([]);
    const [rooms, setRooms] = useState(formatRooms(bookingDetails?.rooms) || []);
    const [temp, setTemp] = useState("");

    const handleAgentChange = (agent_id) => {
        setAgent(agent_id)
        setSubAgent("")
    }
    const fetchSubAgents = async () => {
        if(agent !== ""){
            let res = await dispatch(getSubAgentsForDropdown({ id: agent }));
            setSubAgentsList(res);
        }
    }

    const fetchMealPlans = async () => {
        let mealPlans = await dispatch(getMealPlansForDropdown())
        setMPlans(mealPlans);
    }

    const handleRoomsDataChange = (e, index) => {
        var roomsData = rooms;
        var fieldname = e?.target?.name;
        roomsData[index][fieldname] = e.target.value === "no_preference" ? "" : e.target.value;
        setTemp(e.target.value);
        setRooms(roomsData);
    }

    useEffect(() => {
        fetchMealPlans();
    },[])

    useEffect(() => {
        fetchSubAgents()
    },[agent])

    const handleFormSubmit = async (values) => {
        if(agentEnabled && agent === ""){
            return toast.error("Please select an agent");
        }
        // else if(agentEnabled && agent !== ""){
        //     if(subAgent === ""){
        //         return toast.error("Please select a sub agent");
        //     } else {
        //         let filtered = subAgentsList?.filter((e) => e._id === subAgent)
        //         if(!filtered?.length){
        //             return toast.error("Please select a valid sub agent");
        //         }
        //     }
        // }

        let data = {
            booking_id: values.booking_id,
            title: values.title,
            name: values.name,
            email: values.email,
            custom_price: values.custom_price || 0,
            paid_amount: values.paid_amount || 0,
            guest_name: values.name,
            guest_email: values.email,
            guest_address: values.guest_address,
            guest_mobile: values.guest_mobile,
            remarks: values.remarks,
            // cut_off_days: values.cut_off_days,
            cut_off_date: values.cut_off_date ? moment(values.cut_off_date, "YYYY-MM-DD").startOf("day").unix() : 0,
            preference: values.preference,
            billing_instruction: values.billing_instruction,
            notify_user: notifyUser,
            rooms: bookingDetails?.type === "ota" ? [] : rooms.map(({ roomtype_id, dates, room_no, ...rest }) => rest),
        }
        if(!customPriceEnabled) {
            delete data.custom_price;
        }
        if(agentEnabled){
            data.agent_id = agent;
            data.sub_agent_id = subAgent;
        }
        if (extraBedsEnabled) {
            data.extra_beds = extraBeds;
        }
        console.log(data);
        await dispatch(updateBookingDatas(data));
        setUpdation(false);
    }

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={bookingDetails}
        >
            {({ values, handleChange, handleSubmit }) => (
                <form id="bookingUpdation" onSubmit={handleSubmit}>
                    <Grid container rowSpacing={2} columnSpacing={{ md: 4, xs: 2 }}>
                        <Grid item md={12} xs={12}>
                            <Typography fontWeight="bold" color={colors.greenAccent[500]}>
                                Booking id: {bookingDetails?.booking_id || "-"}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={2}>
                                <Stack direction="row" spacing={2}>
                                    <Typography width={"70%"} mt={0.5}>
                                        Title:
                                    </Typography>
                                    <CssTextField
                                        select
                                        fullWidth
                                        required
                                        size='small'
                                        name="title"
                                        onChange={handleChange}
                                        value={values?.title || "Mr"}
                                    >
                                        {titles?.map((title, index) => (
                                            <MenuItem value={title} key={index}>
                                                {title}
                                            </MenuItem>
                                        ))}
                                    </CssTextField>
                                </Stack>
                                <UpdationInput
                                    required
                                    inputLabel={"Name"}
                                    field={"name"}
                                    value={values?.name || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    // required={!agentEnabled}
                                    inputLabel={"Email"}
                                    field={"email"}
                                    value={values?.email || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    // required={!agentEnabled}
                                    type="number"
                                    inputLabel={"Mobile"}
                                    field={"guest_mobile"}
                                    value={values?.guest_mobile}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    type="number"
                                    inputLabel={"Paid amount"}
                                    field={"paid_amount"}
                                    value={values?.paid_amount}
                                    handleChange={handleChange}
                                    max={values?.custom_price || values?.total_price}
                                />
                                {/* <UpdationInput
                                    type="number"
                                    inputLabel={"Special price"}
                                    field={"custom_price"}
                                    value={values?.custom_price ?? ""}
                                    handleChange={handleChange}
                                /> */}
                                <Stack direction="row" alignItems="center">
                                    <Typography noWrap>Special price</Typography>
                                    <Switch
                                        checked={customPriceEnabled}
                                        onChange={(e) => setCustomPriceEnabled(e.target.checked)}
                                    />
                                </Stack>
                                {customPriceEnabled && (
                                    <Stack direction="row" spacing={2}>
                                        <Typography width={"70%"}>
                                            Special price:
                                        </Typography>
                                        <CssTextField
                                            fullWidth
                                            required={customPriceEnabled}
                                            size='small'
                                            type="number"
                                            placeholder="Special price"
                                            name="custom_price"
                                            value={values?.custom_price ?? ""}
                                            onChange={handleChange}
                                            InputProps={{ inputProps: { min: 0 } }}
                                        />
                                        {/* <UpdationInput
                                            type="number"
                                            inputLabel={"Special price"}
                                            field={"custom_price"}
                                            value={values?.custom_price}
                                            handleChange={handleChange}
                                        /> */}
                                    </Stack>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={2}>
                                {/* <UpdationInput
                                    required
                                    type="number"
                                    inputLabel={"Cut off period (in days)"}
                                    field={"cut_off_days"}
                                    value={values?.cut_off_days}
                                    handleChange={handleChange}
                                /> */}
                                <UpdationInput
                                    type="date"
                                    inputLabel={"Cut off date"}
                                    field={"cut_off_date"}
                                    value={values?.cut_off_date}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    inputLabel={"Preference"}
                                    field={"preference"}
                                    value={values?.preference || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    inputLabel={"Billing instruction"}
                                    field={"billing_instruction"}
                                    value={values?.billing_instruction || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    multiline
                                    minRows={2}
                                    inputLabel={"Address"}
                                    field={"guest_address"}
                                    value={values?.guest_address || ""}
                                    handleChange={handleChange}
                                />
                                <UpdationInput
                                    multiline
                                    minRows={2}
                                    inputLabel={"Remarks"}
                                    field={"remarks"}
                                    value={values?.remarks || ""}
                                    handleChange={handleChange}
                                />
                                <Stack direction="row" alignItems="center">
                                    <Typography noWrap>Agent booking</Typography>
                                    <Switch
                                        checked={agentEnabled}
                                        onChange={(e) => setAgentEnabled(e.target.checked)}
                                    />
                                </Stack>
                                {agentEnabled && (
                                    <>
                                        <Stack direction="row" spacing={2}>
                                            <Typography width={"70%"}>
                                                Agent / Agency:
                                            </Typography>
                                            <SearchAndSelectInput
                                                size="small"
                                                label="Agent / Agency"
                                                keyname="first_name"
                                                value={agent}
                                                required={agentEnabled}
                                                list={agentsList}
                                                onChange={(id) => handleAgentChange(id)}
                                            />
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <Typography width={"70%"}>
                                                Sub Agent:
                                            </Typography>
                                            <SearchAndSelectInput
                                                size="small"
                                                label="Sub Agent"
                                                keyname="name"
                                                value={subAgent}
                                                // required={agentEnabled}
                                                list={subAgentsList}
                                                disabled={!agent || agent === ""}
                                                onChange={(id) => setSubAgent(id)}
                                            />
                                        </Stack>
                                    </>
                                )}
                                <Stack direction="row" alignItems="center">
                                    <Typography noWrap>Extra beds</Typography>
                                    <Switch
                                        checked={extraBedsEnabled}
                                        onChange={(e) => setExtraBedsEnabled(e.target.checked)}
                                    />
                                </Stack>
                                {extraBedsEnabled && (
                                    <Stack direction="row" spacing={2}>
                                        <Typography width={"70%"}>
                                            Number of extra beds:
                                        </Typography>
                                        <PlusOrMinusButton
                                            min={1}
                                            max={10}
                                            size="small"
                                            name="extraBeds"
                                            value={extraBeds}
                                            handleChange={(value) => setExtraBeds(value)}
                                        />
                                    </Stack>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Grid container spacing={2} mt={2}>
                                {rooms?.map((element, index) => {
                                    let mealPlans = mPlans?.filter((e) => e.roomtype_id === element?.roomtype_id)
                                    return (
                                        <Grid item md={6} xs={12} key={index}>
                                            <Box
                                                width="100%"
                                                p={2}
                                                borderRadius={2}
                                                backgroundColor={colors.primary[500]}
                                            >
                                                <Stack spacing={2}>
                                                    <Stack color={colors.greenAccent[500]} fontWeight={600}>
                                                        Room no: {element?.room_no}
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"40%"} mt={1}>
                                                            Selected dates:
                                                        </Typography>
                                                        <Chip label={element?.dates} size='small' />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"70%"}>
                                                            Number of adults:
                                                        </Typography>
                                                        <CssTextField
                                                            fullWidth
                                                            required
                                                            size='small'
                                                            type="number"
                                                            placeholder="Number of adults"
                                                            name="no_of_adults"
                                                            value={element?.no_of_adults}
                                                            onChange={(e) => handleRoomsDataChange(e, index)}
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                            disabled={bookingDetails?.type === "ota"}
                                                        />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"70%"}>
                                                            Number of children:
                                                        </Typography>
                                                        <CssTextField
                                                            fullWidth
                                                            required
                                                            size='small'
                                                            type="number"
                                                            placeholder="Number of children"
                                                            name="no_of_childs"
                                                            value={element?.no_of_childs}
                                                            onChange={(e) => handleRoomsDataChange(e, index)}
                                                            InputProps={{ inputProps: { min: 0 } }}
                                                            disabled={bookingDetails?.type === "ota"}
                                                        />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography width={"70%"}>
                                                            Meal plan:
                                                        </Typography>
                                                        <CssTextField
                                                            fullWidth
                                                            select
                                                            size="small"
                                                            name='meal_plan_id'
                                                            value={element?.meal_plan_id || "no_preference"}
                                                            onChange={(e) => handleRoomsDataChange(e, index)}
                                                            disabled={bookingDetails?.type === "ota"}
                                                        >
                                                            <MenuItem value={"no_preference"}>
                                                                {"Room Only"}
                                                            </MenuItem>
                                                            {mealPlans?.map((e) => (
                                                                <MenuItem value={e?._id} key={e?._id}>
                                                                    <Stack
                                                                        width="100%"
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        spacing={2}
                                                                    >
                                                                        <Typography>
                                                                            {e?.name?.toUpperCase()}
                                                                        </Typography>
                                                                        <Typography>
                                                                            ₹{e?.rate}
                                                                        </Typography>
                                                                    </Stack>
                                                                </MenuItem>
                                                            ))}
                                                        </CssTextField>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Stack direction="row" alignItems="center">
                                <Checkbox
                                    checked={notifyUser}
                                    onChange={(e) => setNotifyUser(e.target.checked)}
                                />
                                <Typography noWrap>Send email</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
}

const UpdationInput = ({ inputLabel, field, value, handleChange, required, type = "text", size = "small", multiline, minRows, min = 0, max }) => {
    return (
        <Stack direction="row" spacing={2}>
            <Typography width={"70%"} mt={0.5}>
                {inputLabel}: {required ? "*" : ""}
            </Typography>
            <CssTextField
                fullWidth
                multiline={multiline}
                minRows={minRows || ""}
                required={required}
                size={size}
                type={type}
                placeholder={`${inputLabel} ${required ? "*" : ""}`}
                name={field}
                value={value}
                onChange={handleChange}
                inputProps={type === "number" ? {min: min, max: max || undefined} : undefined}
            />
        </Stack>
    )
}